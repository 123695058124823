import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowRight,
  faArrowRightToBracket,
  faBoxArchive,
  faCheckCircle,
  faChevronCircleRight,
  faCircleCheck,
  faCirclePlay,
  faCircleXmark,
  faClock,
  faComments,
  faFile,
  faFilePdf,
  faLayerGroup,
  faStar,
  faTableColumns,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import FeatherIcon from "react-feather";

import {
  Accordion,
  AccordionButton,
  Badge,
  Button,
  Card,
  Col,
  Container,
  ListGroup,
  Offcanvas,
  Row,
  Table,
} from "react-bootstrap";
import { Link, json, redirect, useParams } from "react-router-dom";
import { getAuthData, ucfirst } from "../util/auth";
import CourseContentSummary from "../components/CourseContentSummary";
import { ucwords } from "../util/general";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import MoodleModuleIframe from "./MoodleModuleIframe";

function CourseStartActivity() {
  const params = useParams();
  const isMobile = window.innerWidth <= 575.98;
  const [data, setData] = useState(null);
  const [dataSummary, setDataSummary] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [activeRow, setActiveRow] = useState(null);

  const [showIframe, setShowIframe] = useState(false);

  const handleCloseIframe = () => setShowIframe(false);
  const handleShowIframe = () => setShowIframe(true);

  const { toggleSidebar } = useOutletContext();

  const nest_url = process.env.REACT_APP_NEST_URL;
  const url = nest_url + "public/me/get_course_content";
  const secretCode = process.env.REACT_APP_SECRET_CODE;

  const token = getAuthData();

  const [openUrl, setOpenUrl] = useState("");
  const [modName, setModName] = useState("");
  const [selectedModName, setSelectedModName] = useState("");

  const headers = {
    Authorization: `Bearer ${token.jwt}`,
    secret: secretCode,
    "Content-Type": "application/json",
  };

  const fetchEvents = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          courseid: params.courseId,
          subcategoryid: params.subCategoryId,
          userid: params.userId,
        }),
      });

      const responseBody = await response.json();

      if (!responseBody.success) {
        const error = new Error("An error occurred while fetching the course");
        error.code = response.status;
        error.info = "error";
        throw error;
      }

      setData(responseBody);
      console.log(responseBody);

      setLastModId(responseBody.data.original.last_accessed.id);
      setOpenUrl(responseBody.data.original.last_accessed.open_url);
    } catch (error) {
      console.error("Fetch error:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // async function fetchEvents() {
    //     setIsLoading(true);
    //     try {
    //         const response = await fetch(
    //             url,
    //             {
    //                 method: "POST",
    //                 headers: headers,
    //                 body: JSON.stringify({
    //                     'courseid': params.courseId,
    //                     'subcategoryid': params.subCategoryId,
    //                     'userid': params.userId,
    //                 }),
    //             }
    //         );

    //         const responseBody = await response.json();

    //         if (!responseBody.success) {
    //             const error = new Error('An error occurred while fetching the course');
    //             error.code = response.status;
    //             error.info = 'error';
    //             throw error;
    //         }

    //         setData(responseBody);

    //         setLastModId(responseBody.data.original.last_accessed.id);
    //         setOpenUrl(responseBody.data.original.last_accessed.url);

    //     } catch (error) {
    //         console.error("Fetch error:", error);
    //         setError(error);
    //     } finally {
    //         setIsLoading(false);
    //     }
    // }

    fetchEvents();
  }, []);

  const GetIcon = (status) => {
    if (status === 1 || status === 2) {
      return (
        <FontAwesomeIcon
          icon={faCircleCheck}
          style={{ background: "transparent" }}
        />
      );
    } else if (status === 3) {
      return (
        <FontAwesomeIcon
          icon={faCircleCheck}
          style={{ background: "transparent" }}
        />
      );
    }
  };

  const getIconPrefix = (modname, file_ext) => {
    if (modname === "forum") {
      return faComments;
    } else if (modname === "resource") {
      if (file_ext === "mp4") {
        return faCirclePlay;
      } else if (file_ext === "pdf") {
        return faFilePdf;
      } else {
        return faFile;
      }
    } else if (modname === "scorm") {
      return faBoxArchive;
    } else if (modname === "chat") {
      return faComments;
    } else {
      return faFile;
    }
  };

  const [lastModId, setLastModId] = useState(null);

  const handleOnload = async () => {
    console.log("loaded");
  };

  const handleTdClick = async (url, name, modId, index, modName) => {
    setOpenUrl(url);
    setModName(modName);
    setSelectedModName(name);
    setActiveRow(index);
    handleHideList();
    toggleSidebar(false);

    try {
      const response = await fetch(nest_url + "public/me/setModActivityLog", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          courseId: params.courseId,
          modId: modId,
          userId: params.userId,
        }),
      });

      if (!response.ok) {
        throw new Error("Could not fetch data");
      }

      const responseBody = await response.json();
      if (responseBody.success) {
        setLastModId(modId);
      }
    } catch (error) {
      setError(error);
    }
  };

  let ScoreBadgeColor = "#eb5757";
  let CourseStatusBadgeColor = "#f2994a";
  let ProgressBadgeColor = "#f2994a";

  const [showList, setShowList] = useState(true);

  const handleShowList = () => {
    setShowList(true);
  };

  const handleHideList = () => {
    setShowList(false);
  };

  if (isMobile) {
    return (
      <>
        <Container fluid className="p-0  ">
          <Row className="pt-3">
            {data ? (
              <>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="d-flex justify-content-start"
                >
                  <Link to={`/`} style={{ textDecoration: "none" }}>
                    <h6>Dashboard</h6>
                  </Link>
                  <h6>&nbsp; &gt; &nbsp;</h6>
                  <Link to={`/course`} style={{ textDecoration: "none" }}>
                    <h6> Course &nbsp; &gt; &nbsp;</h6>
                  </Link>
                </Col>
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="d-flex justify-content-start"
                >
                  <Link
                    to={`/course/detail/${params.courseId}/${params.subCategoryId}/${params.userId}`}
                    style={{ textDecoration: "none" }}
                  >
                    <h6> {data.data.original.fullname}</h6>
                  </Link>
                  <h6>&nbsp; &gt; &nbsp;</h6>
                  <h6> Start Course</h6>
                </Col>
              </>
            ) : (
              <div
                className="pt-3 pb-2 mr-3"
                style={{ backgroundColor: "transparent" }}
              >
                <div
                  className="skeleton-wrapper"
                  style={{ backgroundColor: "transparent" }}
                >
                  <Skeleton style={{ height: "20px", borderRadius: "15px" }} />
                </div>
              </div>
            )}
          </Row>
          <Row>
            <Col className="mb-3">
              {data !== null ? (
                <Card>
                  <Card.Body className="card-course-content">
                    <Col>
                      <Card.Title
                        className="d-flex justify-content-between align-items-center"
                        style={{
                          fontSize: "28px",
                          fontWeight: "bold",
                          backgroundColor: "white",
                        }}
                      >
                        {data.data.original.fullname}
                      </Card.Title>
                    </Col>
                    <Col className="p-0 bg-white">
                      <table
                        className="bg-white"
                        style={{ border: "none", backgroundColor: "white" }}
                      >
                        <tbody>
                          <tr>
                            <td
                              style={{
                                padding: "10px",
                                border: "none",
                                backgroundColor: "white",
                              }}
                            >
                              Score
                              <br />
                              <span
                                className="badge badge-danger p-2 w-100 text-start"
                                style={{ backgroundColor: ScoreBadgeColor }}
                              >
                                <FontAwesomeIcon
                                  icon={faStar}
                                  style={{
                                    marginRight: "5px",
                                    backgroundColor: "transparent ",
                                  }}
                                />
                                {data.data.original.grade}
                              </span>
                            </td>
                            <td
                              style={{
                                padding: "10px",
                                border: "none",
                                backgroundColor: "white",
                              }}
                            >
                              Course Status
                              <br />
                              <span
                                className="badge badge-danger p-2  w-100 text-start"
                                style={{
                                  backgroundColor: CourseStatusBadgeColor,
                                  fontWeight: "normal",
                                }}
                              >
                                {ucfirst(data.data.original.completionStatus)}
                              </span>
                            </td>
                            <td
                              style={{
                                padding: "10px",
                                border: "none",
                                backgroundColor: "white",
                              }}
                            >
                              Progress
                              <br />
                              <span
                                className="badge badge-danger p-2  w-100 text-start"
                                style={{
                                  backgroundColor: ProgressBadgeColor,
                                  fontWeight: "normal",
                                }}
                              >
                                {data.data.original.percent_complete} %
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Card.Body>
                </Card>
              ) : (
                <div className="" style={{ backgroundColor: "transparent" }}>
                  <div
                    className="skeleton-wrapper"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <Skeleton
                      style={{ height: "200px", borderRadius: "15px" }}
                    />
                  </div>
                </div>
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              {data !== null ? (
                <Card>
                  <Card.Body
                    className="bg-white"
                    style={{ borderRadius: "15px" }}
                  >
                    <ListGroup variant="flush">
                      {data.data.original.contents &&
                        data.data.original.contents.map((item, index) => (
                          <ListGroup.Item key={index} className="p-0 pt-2 pb-2">
                            <b
                              style={{
                                color: "#0f4372",
                                backgroundColor: "transparent",
                              }}
                            >{`${index + 1}. ${item.name}`}</b>
                            <Row style={{ backgroundColor: "transparent" }}>
                              <Col style={{ backgroundColor: "transparent" }}>
                                <ListGroup variant="flush">
                                  {item.modules.length > 0 &&
                                    item.modules.map(
                                      (moduleItem, moduleIndex) => (
                                        <ListGroup.Item
                                          key={moduleIndex}
                                          style={{ background: "white" }}
                                        >
                                          <Col
                                            key={moduleIndex}
                                            className={`${
                                              lastModId === moduleItem.id
                                                ? "active-row-module"
                                                : ""
                                            }`}
                                            style={{
                                              cursor: "pointer",
                                              background: "white",
                                            }}
                                          >
                                            <Row
                                              onLoad={handleOnload}
                                              onClick={
                                                moduleItem.allowed
                                                  ? () =>
                                                      handleTdClick(
                                                        moduleItem.open_url,
                                                        moduleItem.name,
                                                        moduleItem.id,
                                                        moduleIndex,
                                                        moduleItem.modname
                                                      )
                                                  : undefined
                                              }
                                              className="p-0"
                                              style={{ background: "white" }}
                                            >
                                              <Col
                                                className=""
                                                style={{
                                                  background: "white",
                                                  fontSize: "13px",
                                                  color: "#0f4372",
                                                }}
                                              >
                                                <Row
                                                  style={{
                                                    background: "white",
                                                  }}
                                                >
                                                  <Col
                                                    style={{
                                                      background: "white",
                                                    }}
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={getIconPrefix(
                                                        moduleItem.modname,
                                                        moduleItem.file_ext
                                                      )}
                                                      style={{
                                                        background: "white",
                                                        fontSize: "13px",
                                                      }}
                                                    />{" "}
                                                    <b
                                                      style={{
                                                        background: "white",
                                                      }}
                                                    >
                                                      {ucwords(
                                                        moduleItem.modname
                                                      )}
                                                      :
                                                    </b>{" "}
                                                    {moduleItem.name}
                                                  </Col>
                                                </Row>
                                              </Col>
                                              <Col
                                                lg="1"
                                                className=" d-flex justify-content-end align-items-center"
                                                style={{
                                                  background: "white",
                                                  fontSize: "13px",
                                                  color: "#0f4372",
                                                  paddingRight: "33px",
                                                }}
                                              >
                                                <GetIcon />
                                              </Col>
                                            </Row>
                                          </Col>
                                        </ListGroup.Item>
                                      )
                                    )}
                                </ListGroup>
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        ))}
                    </ListGroup>
                  </Card.Body>
                </Card>
              ) : (
                <div className="" style={{ backgroundColor: "transparent" }}>
                  <div
                    className="skeleton-wrapper"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <Skeleton
                      style={{ height: "200px", borderRadius: "15px" }}
                    />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>

        <Offcanvas
          show={showIframe}
          onHide={handleCloseIframe}
          placement="bottom"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Offcanvas</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            Some text as placeholder. In real life you can have the elements you
            have chosen. Like, text, images, lists, etc.
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  } else {
    return (
      <>
        <Container fluid className="p-0  ">
          <Row className="">
            {data ? (
              <div className="d-flex justify-content-start pt-3 pb-2 mr-3">
                <Link to={`/`} style={{ textDecoration: "none" }}>
                  <h6>Dashboard</h6>
                </Link>
                <h6>&nbsp; &gt; &nbsp;</h6>
                <Link to={`/course`} style={{ textDecoration: "none" }}>
                  <h6>Course</h6>
                </Link>
                <h6>&nbsp; &gt; &nbsp;</h6>
                <Link
                  to={`/course/detail/${params.courseId}/${params.subCategoryId}/${params.userId}`}
                  style={{ textDecoration: "none" }}
                >
                  <h6>{data.data.original.fullname}</h6>
                </Link>
                <h6>&nbsp; &gt; &nbsp;</h6>
                <h6>Start Course</h6>
              </div>
            ) : (
              <div
                className="pt-3 pb-2 mr-3"
                style={{ backgroundColor: "transparent" }}
              >
                <div
                  className="skeleton-wrapper"
                  style={{ backgroundColor: "transparent" }}
                >
                  <Skeleton style={{ height: "20px", borderRadius: "15px" }} />
                </div>
              </div>
            )}
          </Row>

          <Row className="m-0">
            <Col>
              {data ? (
                <Card>
                  <Card.Body
                    style={{
                      backgroundColor: "white",
                      borderRadius: "15px",
                      border: "none",
                    }}
                  >
                    <Card.Title className="mb-4">
                      {data.data.original.contents[0] && (
                        <h3 style={{ backgroundColor: "white" }}>
                          {selectedModName}
                        </h3>
                      )}
                    </Card.Title>
                    <Container fluid>
                      {/* <iframe
                        id="url_frame"
                        src={openUrl}
                        title="Content"
                        style={{
                          width: "100%",
                          height: "500px",
                          border: "none",
                        }}
												referrerPolicy="no-referrer"
                      ></iframe> */}
                      {openUrl == "" ? (
                        <h6>You can select course content in the right bar</h6>
                      ) : (
                        <MoodleModuleIframe
                          srcUrl={openUrl}
                          modName={modName}
                        />
                      )}
                    </Container>
                  </Card.Body>
                </Card>
              ) : (
                <div className="" style={{ backgroundColor: "transparent" }}>
                  <div
                    className="skeleton-wrapper"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <Skeleton
                      style={{ height: "200px", borderRadius: "15px" }}
                    />
                  </div>
                </div>
              )}
            </Col>

            <Col
              id="hide-container"
              lg={1}
              className={showList ? "d-none" : "d-block"}
            >
              <Button
                id="btn-show-list"
                className="mb-4 rounded-pill"
                variant="outline-primary"
                onClick={handleShowList}
              >
                <FontAwesomeIcon
                  icon={faAngleLeft}
                  style={{ fontSize: "20px", background: "transparent" }}
                />
              </Button>
            </Col>

            <Col
              id="list-container"
              lg={3}
              className={showList ? "d-block" : "d-none"}
            >
              {data !== null ? (
                <Card>
                  <Card.Body
                    className=""
                    style={{
                      backgroundColor: "white",
                      borderRadius: "15px 0 0 15px",
                      border: "none",
                      position: "relative",
                      paddingLeft: "0",
                    }}
                  >
                    <Button
                      id="btn-hide-list"
                      className="mb-4"
                      variant="outline-primary"
                      style={{
                        width: "50px",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        borderRadius: "15px 0 15px 0",
                      }}
                      onClick={handleHideList}
                    >
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        style={{ fontSize: "20px", background: "transparent" }}
                      />
                    </Button>

                    <Accordion
                      bsPrefix
                      className="mt-4 bg bg-white  "
                      defaultActiveKey="0"
                      id="accordion-module"
                    >
                      {data.data.original.contents &&
                        data.data.original.contents.map((item, index) => (
                          <Accordion.Item
                            eventKey={`item-${item.id}`}
                            key={`item-${item.id}`}
                          >
                            <Accordion.Header className="p-3">
                              <Col
                                className=""
                                onClick={() =>
                                  setIsAccordionOpen((prevState) => ({
                                    ...prevState,
                                    [index]: !prevState[index],
                                  }))
                                }
                              >
                                <b>{`${index + 1}. ${item.name}`}</b>
                              </Col>
                              <Col
                                className="d-flex justify-content-end"
                                onClick={() =>
                                  setIsAccordionOpen((prevState) => ({
                                    ...prevState,
                                    [index]: !prevState[index],
                                  }))
                                }
                              >
                                <FontAwesomeIcon
                                  icon={
                                    isAccordionOpen[index]
                                      ? faAngleUp
                                      : faAngleDown
                                  }
                                />
                              </Col>
                              {/* <div
                                                            onClick={() => setIsAccordionOpen(prevState => ({
                                                                ...prevState,
                                                                [index]: !prevState[index]
                                                            }))}
                                                            style={{ border: 'none', color: '#0f4372' }}
                                                        >
                                                            <FontAwesomeIcon icon={isAccordionOpen[index] ? faAngleUp : faAngleDown}/>
                                                        </div> */}
                            </Accordion.Header>

                            <Accordion.Body
                              className=""
                              style={{ backgroundColor: "#f0f7fd" }}
                            >
                              {item.modules.length > 0 &&
                                item.modules.map((moduleItem, moduleIndex) => (
                                  <Col
                                    className={` ${
                                      lastModId === moduleItem.id
                                        ? "active-row-module"
                                        : ""
                                    }`}
                                    key={moduleIndex}
                                    style={{
                                      paddingLeft: "15px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Row
                                      onLoad={handleOnload}
                                      onClick={
                                        moduleItem.allowed
                                          ? () =>
                                              handleTdClick(
                                                moduleItem.open_url,
                                                moduleItem.name,
                                                moduleItem.id,
                                                moduleIndex
                                              )
                                          : undefined
                                      }
                                      className="p-2 "
                                    >
                                      <Col
                                        className=""
                                        style={{
                                          background: "transparent",
                                          fontSize: "13px",
                                          color: "#0f4372",
                                        }}
                                      >
                                        <Row>
                                          <Col lg={1}>
                                            <FontAwesomeIcon
                                              icon={getIconPrefix(
                                                moduleItem.modname,
                                                moduleItem.file_ext
                                              )}
                                              style={{
                                                background: "transparent",
                                                fontSize: "13px",
                                              }}
                                            />
                                          </Col>
                                          <Col>
                                            <b>
                                              {ucwords(moduleItem.modname)}:
                                            </b>{" "}
                                            {moduleItem.name}
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col
                                        lg="1"
                                        className=" d-flex justify-content-end align-items-center"
                                        style={{
                                          background: "transparent",
                                          fontSize: "10px",
                                          color: "#0f4372",
                                          paddingRight: "33px",
                                        }}
                                      >
                                        <GetIcon />
                                      </Col>
                                    </Row>
                                  </Col>
                                ))}
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                    </Accordion>
                  </Card.Body>
                </Card>
              ) : (
                <div className="" style={{ backgroundColor: "transparent" }}>
                  <div
                    className="skeleton-wrapper"
                    style={{ backgroundColor: "transparent" }}
                  >
                    <Skeleton
                      style={{ height: "200px", borderRadius: "15px" }}
                    />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }

  // } else {
  //     return null; // or any other fallback UI when data is not available
  //   }
}

export default CourseStartActivity;
