import React, { useEffect, useRef, useState } from "react";

const MoodleModuleIframe = ({ srcUrl, modName }) => {
  const iframeRef = useRef(null);
  const [iframeHeight, setIframeHeight] = useState("600px"); // Default height.

  useEffect(() => {
    const handleIframeMessage = (event) => {
      // Ensure the message is coming from the iframe's origin
      const iframeOrigin = new URL(srcUrl).origin;
      if (event.origin !== iframeOrigin) {
        return; // Ignore messages from unexpected origins
      }

      // Check if the message contains height data
      if (
        event.data &&
        typeof event.data === "string" &&
        event.data.startsWith("height:")
      ) {
        const height = event.data.split(":")[1];
        setIframeHeight(`${height}px`); // Adjust iframe height dynamically
      }
    };

    if (modName != "resource") {
      // Add event listener for messages from iframe
      window.addEventListener("message", handleIframeMessage);

      return () => {
        // Clean up event listener on component unmount
        window.removeEventListener("message", handleIframeMessage);
      };
    }
  }, [srcUrl]);

  return (
    <iframe
      ref={iframeRef}
      src={srcUrl}
      width="100%"
      height={iframeHeight}
      frameBorder="0"
      style={{ border: "none" }}
      title="Moodle Module Content"
    ></iframe>
  );
};

export default MoodleModuleIframe;
